<template>
	<div class="row">
		<div v-for="hotel in items" :key="hotel.ID" class="hotel columns column6">
			<div class="hotel-image">
				<picture>
					<source
						v-if="hotel.overviewImageLogoWebp"
						:srcset="hotel.overviewImageLogoWebp"
						type="image/webp"
					/>
					<source :srcset="hotel.overviewImageLogo" />
					<img
						:src="hotel.overviewImageLogo"
						:alt="hotel.overviewImageLogoAlt"
						class="overview-logo"
						loading="lazy"
					/>
				</picture>
				<picture>
					<source v-if="hotel.overviewImageWebp" :srcset="hotel.overviewImageWebp" type="image/webp" />
					<source :srcset="hotel.overviewImage" />
					<img :src="hotel.overviewImage" :alt="hotel.overviewImageAlt" loading="lazy" />
				</picture>
			</div>
			<div class="hotel-content">
				<div class="contentblock-content">
					<h4>{{ hotel.header }}</h4>
					<div v-parse-links v-html="hotel.content" />
					<a v-if="hotel.url" class="button no-fill" :href="hotel.url" target="_blank">
						{{ $t('visitWebsite') }}
					</a>
					<a v-if="hotel.bookingID" class="button cta" :href="hotel.bookingID" target="_blank">
						{{ button }}
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

defineProps({
	items: { type: Array, default: () => [] },
	button: { type: String, default: '' },
});

onMounted(() => {
	Fancybox.bind('[data-fancybox]', {});
});
</script>

<style lang="scss" scoped>
.hotel {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	justify-content: space-between;
	padding: 0 20px;
	margin: 40px auto;
	position: relative;
	z-index: 3;

	.button {
		margin: 5px 5px 5px 0;
	}

	.no-fill {
		color: #fff;
		border-color: #fff;

		&:hover {
			border-color: var(--cta-color-hover);
		}
	}

	.hotel-image {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 70px;
		width: 46%;
		z-index: 2;
		border-radius: 20px;
		overflow: hidden;
	}

	img {
		object-fit: cover;
		width: 100%;
		height: 100%;

		&.overview-logo {
			position: absolute;
			z-index: 3;
			top: 50%;
			left: 0;
			right: 0;
			margin: -50px auto 0;
			width: 80%;
			max-width: 190px;
			height: auto;
		}
	}

	.hotel-content {
		position: relative;
		z-index: 1;
		width: calc(100% - 80px);
		margin: 80px 0 0 80px;
		padding: 80px 40px 80px calc(46% - 40px);
		color: #fff;
		background: #7b8ea1;

		h4,
		:deep(h4) {
			color: #fff;
		}
	}

	&:first-child {
		.hotel-content {
			background: #c19d8b;
		}
	}

	&:nth-child(2) {
		.hotel-content {
			background: #a3ac98;
		}
	}

	&:last-child {
		.hotel-content {
			background: #87410e;
		}
	}
}

@media (max-width: 1480px) {
	.hotel {
		.hotel-image {
			width: 300px;
		}

		.hotel-content {
			width: calc(100% - 80px);
			padding: 80px 40px 80px 240px;
		}
	}
}

@media (max-width: 1200px) {
	.hotel {
		.hotel-image {
			width: 250px;
		}

		.hotel-content {
			width: calc(100% - 80px);
			padding: 80px 40px 80px 190px;
		}
	}
}

@media (max-width: 1080px) {
	.hotel {
		.hotel-image {
			width: 48%;
		}

		.hotel-content {
			width: calc(100% - 80px);
			padding: 80px 40px 80px calc(48% - 40px);
		}
	}
}

@media (max-width: 680px) {
	.hotel {
		.hotel-image {
			position: relative;
			width: calc(100% - 20px);
			margin: 0 20px -80px 0;
			inset: auto;
		}

		.hotel-content {
			margin: 0 0 0 20px;
			padding: 140px 20px 40px;
			width: calc(100% - 20px);
		}
	}
}
</style>
